import React from 'react';
import {
  Added,
  Fixed,
  Link,
  PageWithSubNav,
  Paragraph,
  PlatformHeader,
} from '../../../../components';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav pageType="design" title="June 2021" subnav="release-notes">
      <div id="June2021" className={styles.release}>
        <Paragraph className="uni-margin--one--vertical">
          This tiny-but-mighty patch release contains a couple enhancements to
          the{' '}
          <Link href="/components/forms/select/code?web#Select">Selects</Link>{' '}
          and{' '}
          <Link href="/components/forms/select/code?web#Creatable%20Select">
            CreatableSelects
          </Link>{' '}
          as well as some pretty nifty enhancements to our{' '}
          <Link href="https://uniform-storybook.hudltools.com">
            Uniform Storybook
          </Link>
          .
        </Paragraph>
        <div className={styles.releaseInfo}>
          <div className={styles.platform}>
            <PlatformHeader
              libName="Components"
              version="4.8.1"
              versionUrl="https://github.com/hudl/uniform-ui-components/releases/tag/v4.8.1"
            />

            <div className={styles.changesList}>
              <Fixed>
                Issue with text getting clipped vertically within{' '}
                <Link href="/components/forms/select/code?web#Select">
                  Selects
                </Link>
                .
              </Fixed>
              <Fixed>
                Issue with{' '}
                <Link href="/components/forms/select/code?web#Select">
                  Selects
                </Link>{' '}
                and{' '}
                <Link href="/components/forms/select/code?web#Creatable%20Select">
                  CreatableSelects
                </Link>{' '}
                which allowed changing values when <code>isDisabled</code> or{' '}
                <code>isReadOnly</code> were set to <code>true</code>.
              </Fixed>
            </div>
          </div>
          <div className={styles.platform}>
            <PlatformHeader libName="Docs" />

            <div className={styles.changesList}>
              <Fixed>Issues with search results.</Fixed>
              <Added>
                <Link href="https://storybook.js.org/blog/storybook-6-3/">
                  Measure and Outline{' '}
                </Link>
                tools in{' '}
                <Link href="https://uniform-storybook.hudltools.com">
                  Uniform Storybook
                </Link>
                .
              </Added>
            </div>
          </div>
        </div>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
